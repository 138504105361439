<template>
	<div v-if="project && config">
		<nav class="navbar-nav">
			<nav aria-label="breadcrumb">
				<ol class="breadcrumb">
					<li class="breadcrumb-item">
						<router-link :to="{name: 'projects-index'}">Projects</router-link>
					</li>

					<li class="breadcrumb-item">
						<router-link :to="{
								name: 'projects-editor-uuid-index',
								params: {
									uuid: project.Uuid
								}
							}" v-text="`${project.Code} - ${project.Name}`"></router-link>
					</li>

					<li class="breadcrumb-item active" aria-current="page">
						<span class="font-weight-bold" v-text="config.Name"></span>
						<span class="text-small ml-2" v-text="`v. ${config.Version}`"></span>
					</li>

					<li class="breadcrumb-item active">
						Tests
					</li>

				</ol>
			</nav>

		</nav>

		<project-navbar :project="project" :project-config="config"></project-navbar>

		<ul class="nav nav-tabs mt-3">
			<li class="nav-item" v-for="tab in tabs">
				<router-link class="nav-link text-small" exact-active-class="active" :to="tab.link">
					<i class="mr-2" :class="tab.icon"/>
					{{tab.name}}
				</router-link>
			</li>
		</ul>

		<router-view></router-view>
	</div>
</template>

<script>
	import api from '@/api'
	import ProjectNavbar from "../../../../../components/projects/project-navbar";

	export default {
		components: {ProjectNavbar},
		computed: {
			tabs() {
				let tabs = []

				if (this.canRead('simulator')) {
					tabs.push({
								name: 'Simulator',
								link: {
									name: 'projects-editor-uuid-cuuid-tests-index'
								},
								icon: 'fa fa-play'
							},
					)
				}

				if (this.canRead('spinners')) {
					tabs.push(...[
								{
									name: 'Spinner',
									link: {
										name: 'projects-editor-uuid-cuuid-tests-spinner-index'
									},
									icon: 'fa fa-vials'
								},
								{
									name: 'Reports',
									link: {
										name: 'projects-editor-uuid-cuuid-tests-reports-index'
									},
									icon: 'fa fa-flag-checkered'
								},
								{
									name: 'Combinations',
									link: {
										name: 'projects-editor-uuid-cuuid-tests-combinations-index'
									},
									icon: 'fa fa-object-group'
								},
								{
									name: 'Gameplay',
									link: {
										name: 'projects-editor-uuid-cuuid-tests-gameplay-index'
									},
									icon: 'fa fa-gamepad'
								}
							]
					)
				}

				return tabs
			}
		},
		data() {
			return {
				loading: false,
				project: null,
				config: null
			}
		},
		async mounted() {
			this.project = await api.Projects.getProject(this.$route.params.uuid)
			this.config = await api.Projects.getProjectConfig(this.$route.params.cuuid)
			this.$store.commit('SlotConfigurator/currentSlot', this.config.Configuration)
		}
	}
</script>
